<template>
  <v-card>
    <v-card-title class="justify-center">
      Hobbies
    </v-card-title>
    <v-card-text class="mx-auto space-between">
      <v-row>
        <v-col cols="4">
          <v-card class="text-center">
              <v-card-title class="mb-2">
                  <v-icon color="blue">fas fa-microchip</v-icon>
              </v-card-title>
              <v-card-subtitle>
                DIY
              </v-card-subtitle>
            <v-card-text class="text-left">
                Création de circuits électroniques <br>
                Design de PCB <br>
                Impression 3D
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card class="text-center">
            <v-card-title class="mb-2">
                  <v-icon color="green">fas fa-network-wired</v-icon>
              </v-card-title>
              <v-card-subtitle>
                  Internet des objets
              </v-card-subtitle>
            <v-card-text class="text-left">
                Programmation type arduino <br>
                Domotisation de la maison <br>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card class="text-center">
            <v-card-title class="mb-2">
                  <v-icon color="orange">fas fa-running</v-icon>
              </v-card-title>
              <v-card-subtitle>
                  Activités sportives
              </v-card-subtitle>
            <v-card-text class="text-left">
                Activités outdoor en tout genre<br>
                Course à pied, trail<br>
                Badminton<br>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {};
</script>
<style scoped>
.v-card__title {
  font-family: "Armed Lightning";
}
</style>
