<template>
  <v-card height="100%" elevation="10">
    <v-chip color="primary" outlined label class="ma-2">
      <v-icon>fas fa-briefcase </v-icon>
      <span class="ma-2"> Mes compétences particulières </span>
    </v-chip>
    <v-card-text>
      <v-row class="mb-4">
        <v-col cols="1">
          <v-icon class="fab fa-wordpress" color="blue"></v-icon>
        </v-col>
        <v-col cols="11">
          <v-progress-linear
            color="blue"
            height="20"
            value="90"
            striped
            rounded
          ></v-progress-linear>
        </v-col>
      </v-row>
      <br />
      <v-row class="mb-1" align="center">
        <v-col cols="2" sm="1">
          <v-icon class="fab fa-js-square" color="light-green"></v-icon>
          <v-icon class="fab fa-html5" color="yellow darken-4"></v-icon>
          <v-icon class="fab fa-css3-alt" color="light-blue"></v-icon>
        </v-col>
        <v-col cols="10" sm="11">
          <v-progress-linear
            color="yellow darken-4"
            height="20"
            value="95"
            striped
            rounded
            bottom
          ></v-progress-linear>
        </v-col>
      </v-row>
      <br />
      <v-row class="mb-4">
        <v-col cols="1">
          <v-icon class="fab fa-vuejs" color="green accent-2"></v-icon>
        </v-col>
        <v-col cols="11">
          <v-progress-linear
            color="green accent-2"
            height="20"
            value="90"
            striped
            rounded
          ></v-progress-linear>
        </v-col>
      </v-row>
      <br />
      <v-row class="mb-4">
        <v-col cols="1">
          <v-icon class="fab fa-react" color="light-blue"></v-icon>
        </v-col>
        <v-col cols="11">
          <v-progress-linear
            color="light-blue"
            height="20"
            value="75"
            striped
            rounded
          ></v-progress-linear>
        </v-col>
      </v-row>
      <br />
      <v-row class="mb-4">
        <v-col cols="1">
          <v-icon class="fab fa-node" color="light-green"></v-icon>
        </v-col>
        <v-col cols="11">
          <v-progress-linear
            color="light-green"
            height="20"
            value="80"
            striped
            rounded
          ></v-progress-linear>
        </v-col>
      </v-row>
      <v-row class="mb-4">
        <v-col cols="1">
          <v-icon class="fab fa-python" color="blue"></v-icon>
        </v-col>
        <v-col cols="11">
          <v-progress-linear
            color="blue"
            height="20"
            value="60"
            striped
            rounded
          ></v-progress-linear>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "Skills",
};
</script>
