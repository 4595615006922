<template>
  <v-card height="130">
    <v-footer fixed padless>
      <v-card flat width="100%" class="primary lighten-1 text-center">
        <v-card-text>
          <v-btn v-for="icon in icons" :key="icon.type" class="mx-4" icon>
            <a :href="icon.url" target="_blank">
              <v-icon large size="32px" color="white">
                {{ icon.type }}
              </v-icon>
            </a>
          </v-btn>
        </v-card-text>
        <v-card-text class="white--text" color="white">
          <strong>
            {{ new Date().getFullYear() }}
            <v-icon small size="24px" color="white">
              far fa-copyright
            </v-icon>

            <span> — </span>
          </strong>

          <a
            id="fontArmed"
            color="white"
            href="https://www.madeinfck.com"
            target="_blank"
            >MadeInFck</a
          >
        </v-card-text>
      </v-card>
    </v-footer>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    icons: [
      { type: "fab fa-twitter-square", url: "https://twitter.com/madeinfck" },
      { type: "fab fa-github", url: "https://github.com/MadeInFck" },
      {
        type: "fas fa-envelope-square",
        url: "mailto: webmaster@madeinfck.com",
      },
    ],
  }),
};
</script>

<style scoped>
a {
  
  text-decoration: none !important;
}
a:active,
a:hover {
  outline-width: 0;
  text-decoration: none !important;
  background-color: gray;
}
</style>
