<template>
  <v-card class="d-inline-block mx-auto">
    <v-row justify="space-between center" align="center">
      <v-col cols="2" class="ml-4">
        <v-img
          alt="Madeinfck Logo"
          class="rounded align-left"
          contain
          src="../../src/assets/logo.png"
          transition="scale-transition"
          width="200"
        />
      </v-col>
      <v-col cols="9">
        <v-card-title class="headline" cols="5">
          Mon expertise et ma motivation à votre service !
        </v-card-title>
        <v-card-text style="text-align: justify">
          Avant tout passionné par les technologies et les évolutions récentes dans ce domaine, la programmation est un hobby que j'ai transformé en compétence professionnelle.
          Cette compétence, j'ai souhaité la mettre au service de tout le monde, pour qu'une application web ou mobile, un site à créer et à héberger soit, bien au-delà des lignes de code, avant tout un outil au service des clients et qui répond à leurs attentes.
        </v-card-text>
        </v-col>
    </v-row>
  </v-card>
  <!-- <h1 class="d-flex align-center mt-15 isometric" id="fontArmed" color="primary">
      MADEINFCK
    </h1>
    <p class="d-flex align-center isoshadow" id="fontArmed">MADEINFCK</p> -->
</template>

<script>
export default {
  name: "LogoCenter",
};
</script>
