<template>
  <v-card class="mx-auto" elevation="10">
    <v-card-title class="justify-center" primary-title>
      PORTFOLIO
    </v-card-title>
    <v-divider />
    <v-card-actions>
      <v-carousel cycle height="300" show-arrows-on-hover>
        <v-carousel-item
          v-for="(slide, i) in slides"
          :key="i"
          :src="slide.src"
          :href="slide.webUrl"
          target="True"
          cycle
          contain
          color="primary"
        >
        </v-carousel-item>
      </v-carousel>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "Carousel",
  data() {
    return {
      slides: [
        {
          webUrl: "https://madeinfck.com",
          src: require("../assets/madeinfck.png"),
        },
        {
          webUrl: "https://covid19.madeinfck.com",
          src: require("../assets/covid19.png"),
        },
        {
          webUrl: "https://projet7.oc.madeinfck.com",
          src: require("../assets/avisresto.png"),
        },
        {
          webUrl: "https://projet2.oc.madeinfck.com",
          src: require("../assets/chaletetcaviar.png"),
        },
        {
          webUrl: "https://projet3.oc.madeinfck.com",
          src: require("../assets/filmspleinair.png"),
        },
        {
          webUrl: "https://livewall-next-madeinfck.vercel.app",
          src: require("../assets/livewall-next.png"),
        },
      ],
    };
  },
};
</script>
<style scoped>
.v-card__title {
  font-family: "Armed Lightning";
}
</style>
