<template>
    <v-card height="100%" elevation="10">
        <v-chip color="primary" outlined label class="ma-2">
            <v-icon>fas fa-brain</v-icon>
            <span class="ma-2"> Mon parcours </span>
        </v-chip>
        <v-card-text>
            <v-timeline dense small>
                <v-timeline-item>
                    <template v-slot:icon>
                        <v-avatar>
                            <img src="../assets/logo.png" />
                        </v-avatar>
                    </template>
                    <v-card class="elevation-2">
                        <v-card-title class="headline">2016-2018</v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col cols="3">
                                    <v-icon
                                        color="blue"
                                        class="fab fa-wordpress"
                                    ></v-icon>

                                    <v-icon
                                        color="green accent-2"
                                        class="fab fa-vuejs"
                                    ></v-icon>
                                    <v-icon
                                        color="light-blue"
                                        class="fab fa-react"
                                    >
                                    </v-icon>
                                    <v-icon
                                        color="light-green"
                                        class="fab fa-node"
                                    ></v-icon>
                                </v-col>
                                <v-col cols="9">
                                    Formation en autodidacte sur VueJs, ReactJs
                                    et NodeJs
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-timeline-item>
                <v-timeline-item>
                    <template v-slot:icon>
                        <v-avatar>
                            <img src="../assets/logo.png" />
                        </v-avatar>
                    </template>
                    <v-card class="elevation-2">
                        <v-card-title class="headline">2018-2019</v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col cols="3">
                                    <v-img
                                        width="24px"
                                        height="24px"
                                        src="../assets/oc.png"
                                    ></v-img>
                                </v-col>
                                <v-col cols="9">
                                    Diplôme Développeur d'application Frontend
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-timeline-item>
                <v-timeline-item>
                    <template v-slot:icon>
                        <v-avatar>
                            <img src="../assets/logo.png" />
                        </v-avatar>
                    </template>
                    <v-card class="elevation-2">
                        <v-card-title class="headline">2020-2021</v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col cols="3">
                                    <v-row
                                        style="margin-top: 1px; margin-left:2px;"
                                    >
                                        <v-icon
                                            color="blue"
                                            class="fab fa-python"
                                        ></v-icon>
                                        <span style="margin-left: 5px;"> </span>
                                        <v-img
                                            max-width="24px"
                                            max-height="24px"
                                            src="../assets/nextjs_icon.png"
                                        ></v-img>
                                    </v-row>
                                </v-col>
                                <v-col cols="9">
                                    Formation en autodidacte sur Python et
                                    NextJs
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-timeline-item>
            </v-timeline>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: "Education",
};
</script>
