<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <div class="d-flex align-center">
        <v-img
          alt="Madeinfck Logo"
          class="mr-2 rounded-lg"
          contain
          src="../src/assets/logo.png"
          transition="scale-transition"
          width="70"
        />

        <p class="d-flex align-center mt-4" id="fontArmed">
          MADEINFCK
        </p>
      </div>

      <v-spacer></v-spacer>

      <v-btn href="https://madeinfck.com" target="_blank" text>
        <span class="mr-2" id="fontArmed">Mon Blog</span>
        <v-icon>fas fa-external-link-alt</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-container fluid class="grey lighten-4">
        <v-row>
          <v-col md="6" sm="12" xs="12">
            <Education />
          </v-col>
          <v-col md="6" sm="12" xs="12">
            <Skills />
          </v-col>
        </v-row>
        <br />
        <LogoCenter />
        <br />
        <br />
        <Carousel />
        <br />
        <Hobbies />
      </v-container>
      <Footer />
    </v-main>
  </v-app>
</template>

<script>
import LogoCenter from "./components/LogoCenter.vue";
import Education from "./components/Education.vue";
import Skills from "./components/Skills.vue";
import Carousel from "./components/Carousel.vue";
import Footer from "./components/Footer";
import Hobbies from "./components/Hobbies";

export default {
  name: "App",
  components: {
    LogoCenter,
    Education,
    Skills,
    Carousel,
    Footer,
    Hobbies
  },

  data: () => ({
    //
  }),
};
</script>

<style>
@font-face {
  font-family: "Armed Lightning";
  src: url("../src/assets/armedlightningchrome.ttf");
}

#fontArmed {
  font-family: "Armed Lightning";
  font-size: 30px;
  color: white;
}

.isometric {
  font-size: 50px;
  transform: skewY(-26.6deg);
  -webkit-transform: skewY(-26.6deg);
  -moz-transform: skewY(-26.6deg);
  -o-transform: skewY(-26.6deg);
}

.isoshadow {
  font-size: 50px;
  color: rgba(0, 0, 0, 0);
  transform: skew(63deg, -26.6deg);
  -webkit-transform: skew(63deg, -26.6deg);
  -moz-transform: skew(63deg, -26.6deg);
  -o-transform: skew(63deg, -26.6deg);
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
}
</style>
